export default {
  countryVal: {
    _argentina: '阿根廷',
    _australia: '澳大利亚',
    _belarus: '白俄罗斯',
    _bolivia: '玻利维亚',
    _brazil: '巴西',
    _canada: '加拿大',
    _chile: '智利',
    _china: '中国（港澳台地区除外）',
    _costarica: '哥斯达黎加',
    _france: '法国',
    _hungary: '匈牙利',
    _ireland: '爱尔兰',
    _kazakhstan: '哈萨克斯坦',
    _mexico: '墨西哥',
    _mongolia: '蒙古国',
    _namibia: '纳米比亚',
    _netherlands: '荷兰',
    _newzealand: '新西兰',
    _panama: '巴拿马',
    _serbia: '塞尔维亚',
    _southafrica: '南非',
    _unitedstates: '美国',
    _uruguay: '乌拉圭',
    _othercountry: '其他'
  },
  provinceVal: {
    _anhui: '安徽',
    _beijing: '北京',
    _chongqing: '重庆',
    _fujian: '福建',
    _gansu: '甘肃',
    _guangdong: '广东',
    _guangxi: '广西',
    _guizhou: '贵州',
    _hainan: '海南',
    _hebei: '河北',
    _heilongjiang: '黑龙江',
    _henan: '河南',
    _hubei: '湖北',
    _hunan: '湖南',
    _jiangsu: '江苏',
    _jiangxi: '江西',
    _jilin: '吉林',
    _liaoning: '辽宁',
    _neimongol: '内蒙古',
    _ningxia: '宁夏',
    _qinghai: '青海',
    _shaanxi: '陕西',
    _shandong: '山东',
    _shanghai: '上海',
    _shanxi: '山西',
    _sichuan: '四川',
    _tianjin: '天津',
    _xinjiang: '新疆',
    _xizang: '西藏',
    _yunnan: '云南',
    _zhejiang: '浙江'
  },
  // 牛肉新闻国家名称
  countryArray: [
    { label: '国别', value: '国别' },
    { label: '阿根廷', value: '_argentina' },
    { label: '澳大利亚', value: '_australia' },
    { label: '白俄罗斯', value: '_belarus' },
    { label: '玻利维亚', value: '_bolivia' },
    { label: '巴西', value: '_brazil' },
    { label: '加拿大', value: '_canada' },
    { label: '智利', value: '_chile' },
    { label: '中国（港澳台地区除外）', value: '_china' },
    { label: '哥斯达黎加', value: '_costarica' },
    { label: '法国', value: '_france' },
    { label: '匈牙利', value: '_hungary' },
    { label: '爱尔兰', value: '_netherlands' },
    { label: '哈萨克斯坦', value: '_kazakhstan' },
    { label: '墨西哥', value: '_mexico' },
    { label: '蒙古国', value: '_mongolia' },
    { label: '纳米比亚', value: '_namibia' },
    { label: '荷兰', value: '_netherlands' },
    { label: '新西兰', value: '_newzealand' },
    { label: '巴拿马', value: '_panama' },
    { label: '塞尔维亚', value: '_serbia' },
    { label: '南非', value: '_southafrica' },
    { label: '美国', value: '_unitedstates' },
    { label: '乌拉圭', value: '_uruguay' },
    { label: '乌克兰', value: '_ukraine' },
    { label: '立陶宛', value: '_lithuania' },
    { label: '英国', value: '_unitedkingdom' },
    { label: '拉脱维亚', value: '_latvia' },
    { label: '其他', value: '_other' }
  ],
  // eslint-disable-next-line indent
  // 牛肉新闻国家代码
  countryKey: {
    0: '',
    1: '_argentina',
    2: '_austarlia',
    3: '_belarus',
    4: '_bolivia',
    5: '_brazil',
    6: '_canada',
    7: '_chile',
    8: '_china',
    9: '_costarica',
    10: '_france',
    11: '_hungary',
    12: '_ireland',
    13: '_kazakhstan',
    14: '_mexico',
    15: '_mongolia',
    16: '_namibia',
    17: '_netherlands',
    18: '_newzealand',
    19: '_panama',
    20: '_serbia',
    21: '_southafrica',
    22: '_unitedstates',
    23: '_uruguay',
    24: '_ukraine',
    25: '_lithuania',
    26: '_unitedkingdom',
    27: '_latvia',
    28: '_other'
  },
  // 猪肉新闻国家名称
  porkCountryArray: [
    { label: '国别', value: '' },
    { label: '阿根廷', value: '_argentina' },
    { label: '爱尔兰', value: '_ireland' },
    { label: '巴西', value: '_brazil' },
    { label: '丹麦', value: '_denmark' },
    { label: '德国', value: '_germany' },
    { label: '法国', value: '_france' },
    { label: '芬兰', value: '_finland' },
    { label: '荷兰', value: '_netherlands' },
    { label: '加拿大', value: '_canada' },
    { label: '美国', value: '_unitedstates' },
    { label: '葡萄牙', value: '_portugal' },
    { label: '西班牙', value: '_spain' },
    { label: '匈牙利', value: '_hungary' },
    { label: '英国', value: '_unitedkingdom' },
    { label: '智利', value: '_chile' },
    { label: '墨西哥', value: '_mexico' },
    { label: '塞尔维亚', value: '_serbia' },
    { label: '奥地利', value: '_austria' },
    { label: '中国（港澳台地区除外）', value: '_china' },
    { label: '哥斯达黎加', value: '_costarica' },
    { label: '瑞士', value: '_switzerland' },
    { label: '意大利', value: '_italy' },
    { label: '波兰', value: '_poland' },
    { label: '比利时', value: '_belgium' },
    { label: '罗马尼亚', value: '_romania' },
    { label: '其他', value: '_other' }
  ],
  // 猪肉新闻国家代码
  porkCountryKey: {
    0: '',
    1: '_argentina',
    2: '_ireland',
    3: '_brazil',
    4: '_denmark',
    5: '_germany',
    6: '_france',
    7: '_finland',
    8: '_netherlands',
    9: '_canada',
    10: '_unitedstates',
    11: '_portugal',
    12: '_spain',
    13: '_hungary',
    14: '_unitedkingdom',
    15: '_chile',
    16: '_mexico',
    17: '_serbia',
    18: '_austria',
    19: '_china',
    20: '_costarica',
    21: '_switzerland',
    22: '_italy',
    23: '_poland',
    24: '_belgium',
    25: '_romania',
    26: '_other'
  }
}
