<template>
  <div class="content-box">
    <div class="content-button"></div>
    <div class="content-body">
      <div class="body-left"><img class="title-img" src="@/assets/image/btctrend/user.png" /></div>
      <div class="userlist-right">
        <div v-if="myUserTrend != null && myUserTrend.UserID != null" :key="myUserTrend.UserID" @click="handleClickTab()" class="body-my-item">
          <div class="my-img"><img class="title-my-img" :src="getUrl(myUserTrend.Avator)" /></div>
          <div class="item-comany">
             {{ myUserTrend.UserName }}
            <img class="title-sex-img" :src="getSex(myUserTrend.UserSex)" />
          </div>
          <div class="item-other">
            <div>孚龄： {{ myUserTrend.BeefAge }}</div>
            <div>公司属地：{{ getAddr(myUserTrend.CompanyAddr) }}</div>
          </div>
          <div class="item-other">
            <div>
              活跃度：
              <img class="title-other-img" :src="getActivity(myUserTrend.Activity)" />
            </div>
            <div>公司角色：<div v-for="(e,n) in myUserTrend.CompanyAttribute" :key = "n" class="attribute" >{{ e }}</div></div>
          </div>
          <div class="on-line">在线</div>
        </div>
        <transition-group class="group-tran" name="userlist" tag="div">
          <div v-for="(item, index) in list" :key="item.UserID" class="userlist-item">
            <div class="body-right-item">
              <!-- <div class="my-img"><img class="title-my-img" :src="getUrl(item.Avator)" /></div> -->
              <div class="my-img">
                <div :id="'tooltip-img' + index"><img class="title-my-img" :src="getUrl(item.Avator)" /></div>
                <b-tooltip placement="bottom" :target="'tooltip-img' + index" triggers="hover">
                  App专享
                </b-tooltip>
              </div>
              <div class="item-comany">
                {{item.UserName}}
                <img class="title-sex-img" :src="getSex(item.UserSex)" />
              </div>
              <div class="item-other">
                <div>孚龄：{{item.BeefAge}}</div>
                <div>公司属地：{{ getAddr(item.CompanyAddr) }}</div>
              </div>
              <div class="item-other">
                <div>
                  活跃度：
                  <img class="title-other-img" :src="getActivity(item.Activity)" />
                </div>
                <div class="other-role">公司角色：<div v-for="(e,n) in item.CompanyAttribute" :key = "n" class="attribute" >{{ e }}</div></div>
              </div>
              <div class="on-chat">
                <div :id="'tooltip-' + index"><img class="title-chat-img" src="@/assets/image/btctrend/user/chat.png" /></div>
                <b-tooltip placement="bottom" :target="'tooltip-' + index" triggers="hover">
                  App专享
                </b-tooltip>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'
import { getLocalStore } from '@/utils/common'
import dict from '@/utils/dict.js'
import '@/styles/tooltip.css'
const signalR = require('@microsoft/signalr')

export default {
  mixins: [commonmix],
  name: 'CnBTCUesr',
  components: {},
  data () {
    return {
      list: [],
      myUserTrend: {},
      listQuery: {
        UserID: getLocalStore().UserID,
        PageNum: 1,
        PageSize: 20
      },
      current: 0,
      isShowMy: false,
      connection: ''
    }
  },
  computed: {
    userid () {
      return getLocalStore().UserID
    }
  },
  mounted () {
    this.getList(false)
    var _self = this
    if (document.getElementsByClassName('userlist-right')[0]) {
      document.getElementsByClassName('userlist-right')[0].addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop === this.clientHeight) {
          _self.getList(true)
        }
      })
    }
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_CHAT}`, {})
      .configureLogging(signalR.LogLevel.Error)
      .build()
    this.connection.on('ReceiveUserOnlineInfo2', data => {
      if (data.UserID === _self.userid) {
        return
      }
      const user = _self.list.filter((item) => {
        return item.UserID !== data.UserID
      })
      user.unshift(data)
      _self.list = user
    })
    this.connection.start()
  },
  methods: {
    handleClickTab () {
      this.$router.push('Account')
    },
    getList (isMore) {
      if (isMore) {
        this.listQuery.PageNum += 1
      } else {
        this.listQuery.PageNum = 1
      }
      this.$store
        .dispatch('GetBeefUserTrend2', this.listQuery)
        .then(() => {
          if (isMore) {
            this.list = this.list.concat(this.$store.getters.dataTable.UserTrendRows)
          } else {
            this.list = this.$store.getters.dataTable.UserTrendRows
            this.myUserTrend = this.$store.getters.dataTable.UserTrendRow
          }
        })
        .catch(err => {
          if (err.ErrorCode === 6004) {
            this.$router.push('/english/charge')
          }
        })
    },
    getSex (val) {
      return (val && val !== null && val !== undefined) ? require('@/assets/image/btctrend/user/' + val + '.png') : require('@/assets/image/btctrend/user/_mr.png')
    },
    getUrl (addr) {
      if (addr) {
        return `${process.env.VUE_APP_STATIC}${addr}`
      }
    },
    getAddr (val) {
      const addr = dict.provinceVal[val]
      if (addr && addr !== undefined) {
        return addr
      } else {
        return dict.countryVal[val] ? dict.countryVal[val] : val
      }
    },
    getActivity (val) {
      let activityAddr = ''
      switch (val) {
        case -1:
          activityAddr = require('@/assets/image/btctrend/user/nohot.png')
          break
        case 0:
          activityAddr = require('@/assets/image/btctrend/user/lowhot.png')
          break
        case 1:
          activityAddr = require('@/assets/image/btctrend/user/midhot.png')
          break
        case 2:
          activityAddr = require('@/assets/image/btctrend/user/fullhot.png')
          break
      }
      return activityAddr
    },
    handleButton (index) {
      this.current = index
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.btc-banner {
  height: 220px;
  width: 100%;
  border-bottom: 2px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.content-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0 10px 0;
  border-bottom: 2px solid #f5f5f5;
  min-width: 1200px;
}

.content-body {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  min-width: 1200px;
}

.body-left {
  // width: 30%;
  width: 430px;
}

.body-left > img{
  height: 1000px;
}

.userlist-right {
  width: 800px;
  height: 1000px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.body-my-item {
  width: 719px;
  height: 78px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 29px;
  cursor: pointer;
}
.body-right-item {
  width: 719px;
  height: 78px;
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
  margin-left: 29px;
  margin-top: 5px;
  margin-right: 5px;
}
.my-img {
  width: 54px;
  height: 54px;
  background: rgba(255, 255, 255, 0.39);
  opacity: 1;
  margin-left: 22px;
}
.title-my-img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  cursor: pointer;
}
.title-other-img {
  width: 13px;
  height: 13px;
}
.item-comany {
  width: 35%;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  opacity: 1;
  display: flex;
  letter-spacing: 0.5px;
}
.title-sex-img {
  width: 11px;
  height: 11px;
  margin-top: -2px;
}
.item-other {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  opacity: 1;
  text-align: left;
  min-width: 120px;
}
.other-role{
  display: flex;
}
.on-line {
  width: 50px;
  height: 22px;
  border: 1px solid #479e40;
  border-radius: 4px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  color: #479e40;
  text-align: center;
  opacity: 1;
  margin-right: 44px;
}
.on-chat {
  width: 50px;
  height: 22px;
  margin-right: 44px;
  cursor: pointer;
}

.title-chat-img {
  width: 26px;
  height: 24px;
}
// // 滚动条宽度
// .body-right ::-webkit-scrollbar{
//   width: 6px !important;
//   height: 1px !important;
// }

// /* 定义滚动条轨道 */
// .body-right ::-webkit-scrollbar-track{
//   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important;
//   border-radius: 10px !important;
//   background-color: #FFF !important;
// }

// /* 滑块 */
// .body-right ::-webkit-scrollbar-thumb {
//   border-radius: 10px !important;
//   -webkit-box-shadow: inset 0 0 6px rgb(135, 111, 240) !important;
//   background-color: #AAA !important;
// }
.group-tran {
  width: 750px;
}

.userlist-item {
  // transition: all 0.3s;
  // -webkit-transition: all 0.3s;
  // display:inline-block;
  // vertical-align: middle;
  // position: relative;
}

.userlist-enter, .userlist-leave-to {
  transform: translateY(156px);
}
.userlist-enter-active, .userlist-leave-active {
  // position: absolute;
}
.userlist-move {
  transition: transform 1s;
}
</style>
